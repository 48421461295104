﻿@charset 'utf-8';
/* CSS reset */
html{color:#000;background:#FFF;font-family: YakuHanJPs, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", nc3Jp, sans-serif !important;font-weight: 300;}
html,body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,p,td,strong{padding:0;margin:0;font-family: YakuHanJPs, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", nc3Jp, sans-serif; font-weight: 300;}
table{border-collapse:collapse;border-spacing:0;}
fieldset,img{border:0;}
a{text-decoration:none; color:#00c; outline:none;}/*此处待添加默认链接颜色*/
var,em,strong{font-style:normal;}
address,caption,cite,code,dfn,em,strong,th,var,optgroup{font-style:inherit;font-weight:inherit;}
del,ins{text-decoration:none;}
ul li{list-style:none;}
caption,th{text-align:left;}
h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;}
q:before,q:after{content:'';}
abbr,acronym{border:0;font-variant:normal;}
sup{vertical-align:baseline;}
sub{vertical-align:baseline;}
legend{color:#000;}
input,button,textarea,select,optgroup,option{font-family:inherit; font-size:inherit;font-style:inherit;font-weight:inherit;}
input,button,textarea,select{*font-size:100%;}
input{border:none;outline:none;}
abbr, article, aside, audio, canvas, datalist, details, dialog, eventsource, figure, footer, header, hgroup, mark, menu, meter, nav, output, progress, section, time, video{display: block;}
/*body{-webkit-user-select:none;-webkit-text-size-adjust:none;-webkit-transform-style:preserve-3d;}
*{-webkit-tap-highlight-color:rgba(0,0,0,0);}*/
.clearfix:after {
content:"\200B";
display:block;
height:0;
clear:both;
}
.clearfix {
*zoom:1;
}
a{
	outline:none;
	-moz-outline-style:none;
	-webkit-tap-highlight-color: transparent; 
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
a:hover{
	outline:none;
	-moz-outline-style:none;
	-webkit-tap-highlight-color: transparent; 
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}